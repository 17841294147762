.login-form-cover{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}
.login-form-cover>div:first-child{
    height: calc(100vh - 32px);
}
.login-form-cover>div:last-child{
    background-color: #6F6F72;
}
.login-form{
    width: 950px;
    background-color: white;
    border-radius: 3px;
    overflow: hidden;
}
.login-form img{
    width: 95%;
}
.login-form .ant-image{
    display: flex !important;
    justify-content: center !important;
}
.login-form p{
    font-weight: 500;
}
.login-form .light-grey-bg{
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}
.login-form .light-grey-bg .light-grey-text{
    font-size: 13px !important
}
@media only screen and (max-width: 976px) {
    .login-form{
        width: 93%;
    }
    .login-form img{max-width: 400px;}
}
@media only screen and (max-width: 850px) {
    .login-form .light-grey-bg .light-grey-text{
        display: none;
    }
}