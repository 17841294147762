.post-operation-instructions-page{
    font-family: 'Noto Nastaliq Urdu' !important;
    text-align: right !important;
}

.post-operation-instructions-page .ant-typography{
    margin: 0 !important;
    display: flex;
    direction: rtl;
}
.post-operation-instructions-page p{
     direction: rtl;
     margin: 0;
     line-height: 2;
}
.post-operation-instructions-page p>span:first-child{
    font-weight: 600;
}
.post-operation-instructions-page .last-instruction{
    font-size: 15px;
}
.post-operation-instructions-page .last-instruction span, .head-wash-instruction span{
    font-weight: normal !important;
}
.head-wash-instruction strong{
    font-weight: 600 !important;
}
.post-operation-instructions-page .fw-bold{
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px !important;
}
.post-operation-instructions-page .fw-bold-13{
    font-weight: 600;
    font-size: 14px;
}
.post-operation-instructions-page .fw-bold-13::after{
    content: ":";
}
.post-operation-instructions-page{
    width: 100%;
}
.post-operation-instructions-page p{
    font-size: 13px
}
.post-operation-instructions-page input{
    border: none !important;
    font-weight: 600;
}
.post-operation-instructions-page input:focus{
    outline: none !important;
}
.post-operation-instructions-page input[type='date']{
    width: 82px;
    margin-left: 4px;
    position: relative;
}
.post-operation-instructions-page input[type="date"]::-webkit-inner-spin-button,
.post-operation-instructions-page input[type="date"]::-webkit-calendar-picker-indicator,
.post-operation-instructions-page input[type="time"]::-webkit-calendar-picker-indicator{
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    top: 0;
    width: 100%;
    position: absolute;
}
.post-operation-instructions-page input[type='time']{
    width: 68px;
    margin-left: 4px;
    position: relative;
}
@media print {
    @media print {
        .post-operation-instructions-page{
            width: 85% !important ;
        }
        .post-operation-instructions-page header{
            height: 20px;
        }
    }
}