.space-d{
    display: flex;
    align-items: flex-end;
    gap: 5px;
    width: 100%;
}
.prescribed-medicines-page .ant-typography{
    margin: 0 !important;
}
.prescribed-medicines-page input{
    width: 100% !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-radius: 0px !important;
}
.prescribed-medicines ul{
    padding-left: 20px !important;
}
.prescribed-medicines li{
    margin-left: 19px !important;
}
.prescribed-medicines li>div{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 650px;
}
.prescribed-medicines input{
    width: 300px !important;
    height: 40px !important;
    direction: rtl !important;
    font-family: 'Noto Nastaliq Urdu' !important;
}
@media print {
    @media print {
        .prescribed-medicines-page{
            width: 90% !important ;
        }
        .prescribed-medicines-page header {
            height: 150px;
        }
        .prescribed-medicines-form{
            display: none !important;
        }
        .prescribed-medicines-page .client-info{
            margin-bottom: 40px;
        }
        .prescribed-medicines-page .ant-typography{
            font-size: 18px !important;
        }
        .prescribed-medicines-page input, .prescribed-medicines-page .end-note{
            font-size: 18px !important;
        }
        .prescribed-medicines .ant-typography{
            font-size: 17px !important;
        }
        .prescribed-medicines input{
            font-size: 19px !important;
            border: none !important;
            height: 43px !important;
        }
    }
  }