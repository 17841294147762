.After-13-days-instructions-page{
    font-family: 'Noto Nastaliq Urdu' !important;
    text-align: right !important;
}

.After-13-days-instructions-page .ant-typography{
    margin: 0 !important;
    display: flex;
    direction: rtl;
}
.After-13-days-instructions-page p{
     direction: rtl;
     margin: 0;
     line-height: 2;
}
.After-13-days-instructions-page p>span:first-child{
    font-weight: 600;
}
.head-wash-instruction strong{
    font-weight: 600 !important;
}
.After-13-days-instructions-page .fw-bold{
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px !important;
}
.After-13-days-instructions-page .fw-bold-13{
    font-weight: 600;
    font-size: 14px;
}
.After-13-days-instructions-page .fw-bold-13::after{
    content: ":";
}
.After-13-days-instructions-page{
    width: 100%;
}
.After-13-days-instructions-page p{
    font-size: 13px
}
@media print {
    @media print {
        .After-13-days-instructions-page{
            width: 85% !important ;
        }
        .After-13-days-instructions-page header{
            height: 20px;
        }
    }
}