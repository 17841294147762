.detail-row{
    justify-content: space-between !important;
    width: 100% !important;
}
.prp-sessions-bar .ant-space{
    /* min-width: 300px !important;
    max-width: 300px !important;
    overflow-x: auto !important;
    border: 1px solid red ; */
}
@media print {
    @media print {
        .print-fue-appointment-detail{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .print-fue-appointment-detail>div{
            width: 85% !important
        }
        .print-fue-appointment-detail header{
            height: 20px;
        }
    }
}