.print-receipt-table th{
    text-align: center;
}
@media print {
    @media print {
        .pay-receipt-page{
            padding-top: 20px;
            width: 85% !important;
        }
    }
}