.page{
    width: 100%;
}
.fs-13{
    font-size: 14px !important;
}
.page .fw-bold{
    font-weight: 600 !important;
    font-size: 15px !important;
}
@media print {
    @media print {
        .page{
            width: 85% !important 
        }
        .fs-13{
            font-size: 15px !important;
        }
        .page .fw-bold{
            font-weight: 600 !important;
            font-size: 16px !important;
        }
        header {
            height: 20px;
        }
    }
  }