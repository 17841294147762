.space-d{
    display: flex;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
}
.space-d .ant-typography{
    margin: 0 !important;
    display: flex !important;
    flex-shrink: 0 !important;
    font-size: 15px !important;
}
.space-d input{
    width: 100% !important;
    font-size: 15px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-radius: 0px !important
}
.fw-bold{
    font-weight: 600;
    font-size: 15px;
}
.history-page{
    width: 100%;
}
@media print {
    @media print {
        .space-d .ant-typography{
            font-size: 13px !important;
        }
        .space-d input{
            font-size: 13px !important;
        }
        .fw-bold{
            font-size: 13px !important;
        }
        .history-page{
            width: 85% !important ;
        }
        .history-page header{
            /* height: 34px !important; */
        }
    }
  }