
.pre-operation-instructions-page{
    padding-left: 15px;
}
.pre-operation-instructions-page li{
    margin-bottom: 10px;
}
.pre-operation-instructions-page li:last-child{
    margin-bottom: 0px;
    text-decoration: underline;
}
.pre-operation-instructions-page .heading{
    font-size: 20px;
    font-weight: 400;
}
@media print {
    @media print {
        .pre-operation-instructions-page{
            padding-top: 20px;
            width: 85% !important;
        }
    }
}