.playlist{
    display: flex;
    justify-content: space-between;
}
.playlist>div:first-child{
    width: 260px;
}
.playlist>div:last-child{
    width: calc(100% - 280px);
}
.playlist>.left-column{
    width: 300px;
}
.playlist>.right-column{
    width: calc(100% - 320px);
}
.playlist img{
    width: 19px;
}
@media (max-width: 855px){
    .playlist{
        flex-direction: column !important;
    }
    .playlist>div:first-child{
        margin-bottom: 15px;
    }
    .playlist>div:last-child{
        width: 100%
    }
}

.playlist-button button:first-child{
    width: 100% !important;
}