.space-d{
    display: flex;
    align-items: flex-end;
    gap: 5px;
    width: 100%;
}
.after-14-day-prescribed-medicines-page .ant-typography{
    margin: 0 !important;
}
.after-14-day-prescribed-medicines-page input{
    width: 100% !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-radius: 0px !important;
}


.prescribed-medicines input{
    width: 300px !important;
    height: 40px !important;
    direction: rtl !important;
    font-family: 'Noto Nastaliq Urdu' !important;
}
@media print {
    @media print {
        .after-14-day-prescribed-medicines-page{
            width: 90% !important ;
        }
        .after-14-day-prescribed-medicines-page header {
            height: 150px;
        }
        .prescribed-medicines-form{
            display: none !important;
        }
        .prescribed-medicines input{
            border: none !important;
            height: 43px !important;
        }
    }
  }